/**
 * Configures the public and admin apps.
 *
 * Usage:
 *
 * import appConfig from "./appConfig";
 * import publicModule from "./publicModule";

 * export default appConfig(publicModule).name;
 */

export default function (app) {
  // Must not be uglified!
  // cannot be injected because providers are not yet configured
  return app.config([
    "$animateProvider",
    "$compileProvider",
    "$httpProvider",
    "$locationProvider",
    "$logProvider",
    "$routeProvider",
    "$sceDelegateProvider",
    function(
      $animateProvider,
      $compileProvider,
      $httpProvider,
      $locationProvider,
      $logProvider,
      $routeProvider,
      $sceDelegateProvider
    ) {
      if (
        !(location.host.match(/127\.0\.0\.1/) || location.host.match(/localhost/))
      ) {
        $compileProvider.debugInfoEnabled(false);
        $logProvider.debugEnabled(false);
        $sceDelegateProvider.resourceUrlWhitelist(["self"]);
      } else {
        $sceDelegateProvider.resourceUrlWhitelist([
          "self",
          "http://localhost:3000/**",
        ]);
      }

      $locationProvider
        .html5Mode({
          enabled: true,
          requireBase: false,
          rewriteLinks: true,
        })
        .hashPrefix("!");
      $animateProvider.classNameFilter(/nsan-/);

      // so django can use request.is_ajax()
      $httpProvider.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
      // support django csrf tokens
      $httpProvider.defaults.xsrfHeaderName = "X-CSRFToken";
      $httpProvider.defaults.xsrfCookieName = "csrftoken";

      $routeProvider.otherwise({
        controller: "OtherwiseCtrl",
        template: " " // this is needed to force it to process it
      });
    },
  ]).constant("angularMomentConfig", {
    preprocess: "unix",
  })
  .run([
    "inits",
    "SearchFormParams",
    function (inits, SearchFormParams) {
      inits.user();
      inits.analytics();
      inits.onResize();
      inits.ngVsDjango();
      inits.pageVars();
      inits.jsReady();
      SearchFormParams.bootstrap();
    },
  ]);
}
